<template>
    <div class="footer mb-5 pb-5">
        <div class="footer__section">
            <div class="d-flex justify-content-center">
                <a href="https://republish.nl">
                    <img src="../assets/images/republish-logo.svg" alt="" />
                </a>
            </div>
            <div class="py-3 d-flex flex-column justify-content-center align-items-center">
                <div class="py-3 d-flex justify-content-center footer__section-icon">
                    <a href="mailto:info@republish.nl">
                        <p class="text-white">
                            <img class="px-2" src="../assets/images/message.svg" alt="" />
                            info@republish.nl
                        </p>
                    </a>

                    <p class="text-white px-md-5 px-0">
                        <img class="px-2" src="../assets/images/mobile phone.svg" alt="" />
                        +31 6 814 790 63
                    </p>
                    <p class="text-white">
                        <img class="px-2" src="../assets/images/monitor 1.svg" alt="" />
                        Republish.nl
                    </p>
                </div>
                <p class="text-white text-center">
                    <img class="px-2" src="../assets/images/location point.svg" alt="" />
                    Gentseweg 5, 2803 PC, Gouda
                </p>
            </div>

            <div class="d-flex justify-content-center"></div>

            <div class="py-4 d-flex align-items-center justify-content-center">
                <a href="https://nl.linkedin.com/company/re-publish" target="_blank">
                    <button class="footer__section-btn text-white bg-site-orange border-0 d-flex align-items-center justify-content-center">
                        <img class="px-2" src="../assets/images/linkedin.svg" alt="" />
                        Bezoek LinkedIn
                    </button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
};
</script>
<style scoped>
a:hover {
    text-decoration: none !important;
}
</style>
